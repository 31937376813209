import { getI18n } from 'react-i18next';
import MembersListActionColumn from './MembersListActionColumn';
import MembersListTagColumn from './MembersListTagColumn';
import { Button } from 'antd';
import storage from '../../../../../lib/Storage';

const getDefaultSortOrder = (order, value) => {
  const sortOrderMap = {
    [`${value}.asc`]: 'ascend',
    [`${value}.desc`]: 'descend',
  };

  return sortOrderMap[order] || null;
};

const getColumn = (
  profileId,
  titleKey,
  dataIndex,
  key,
  sorter,
  width,
  onclick,
  screen = null,
  sorterFunction = (a, b) => a[dataIndex].localeCompare(b[dataIndex])
) => {
  return {
    title: getI18n().t(titleKey),
    dataIndex,
    key,
    defaultSortOrder: getDefaultSortOrder(sorter, key),
    sorter: sorter ? sorterFunction : null,
    width:
      storage.get('local', `${profileId}_${getI18n().t(titleKey)}`) ?? width,
    render: (item, record) => {
      if (onclick) {
        return (
          <Button
            type='link'
            className='underlined-hyperlink'
            onClick={() => {
              onclick(record.id, screen);
            }}
          >
            {item}
          </Button>
        );
      }
      return item;
    },
  };
};

const getActionColumn = (
  profileId,
  accessCareGuide,
  openEventForm,
  redirectToScreen,
  showResourceForm
) => {
  return {
    title: getI18n().t('table_header_action'),
    key: 'actions',
    width: 77,
    fixed: 'right',
    render: (row) => (
      <>
        <MembersListActionColumn
          row={row}
          accessCareGuide={accessCareGuide}
          openEventForm={openEventForm}
          redirectToScreen={redirectToScreen}
          showForm={showResourceForm}
          profileId={profileId}
        />
      </>
    ),
  };
};

const getTagsColumns = (profileId, data) => {
  return data.map((column) => ({
    title: column.name,
    dataIndex: `table column ${column.name}`,
    key: column.id,
    render: (item) => <MembersListTagColumn item={item} />,
    align: 'center',
    width:
      storage.get('local', `${profileId}_${getI18n().t(column.name)}`) ?? 170,
  }));
};

const getLeftColumns = (profileId, order, redirectToScreen) => [
  getColumn(
    profileId,
    'table_header_caregiver_name',
    'owner_profile_last_name',
    'owner_profile_last_name',
    order,
    180,
    redirectToScreen,
    'CareGuideMembersActions',
    (a, b) => a.owner.profile.last_name.localeCompare(b.owner.profile.last_name)
  ),
  getColumn(
    profileId,
    'table_header_care_member_name',
    'care_guide_last_name',
    'care_guide_last_name',
    order,
    180,
    redirectToScreen,
    'CareGuideMembersActions',
    (a, b) => a.last_name.localeCompare(b.last_name)
  ),
  getColumn(
    profileId,
    'table_header_caregiver_email',
    'caregiver_mail',
    'caregiver_mail',
    null,
    180
  ),
  getColumn(
    profileId,
    'table_header_caregiver_phone',
    'caregiver_phone',
    'caregiver_phone',
    null,
    200
  ),
];

export const getTableColumns = (
  profileId,
  order,
  filters,
  accessCareGuide,
  openEventForm,
  redirectToScreen,
  showResourceForm
) => {
  const leftColumns = getLeftColumns(profileId, order, redirectToScreen);
  const tagsColumns = getTagsColumns(profileId, filters);
  const actionColumn = getActionColumn(
    profileId,
    accessCareGuide,
    openEventForm,
    redirectToScreen,
    showResourceForm
  );
  const newColumns = [...leftColumns, ...tagsColumns, actionColumn];
  return newColumns;
};
