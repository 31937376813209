import React, { useCallback, useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router';
import Logger from '../../../../../lib/Logger';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import { Col, Table, Checkbox } from 'antd';
import MemberListItemsSelectionBar from './MemberListItemsSelectionBar';
import MembersListFilters from './MembersListFilters';
import ResizableTitle from './ResizableTitle';
import { getTableColumns } from './MembersListTableColumns';
import MemberEventForm from '../containers/MemberEventFormContainer';
import storage from '../../../../../lib/Storage';
import MemberResourceForm from '../containers/MemberResourceFormContainer';

const MembersListTable = ({
  page,
  load,
  limit,
  order,
  total,
  filter,
  history,
  profileId,
  component,
  loadFilters,
  loadAllIds,
  partnershipActiveId,
  selectedRowKeys,
  updateSelectedRowKeys,
  updateAllSelectedRowKeys,
  allPageCareGuideOwnerIds,
  accessCareGuide,
  isLoadingIds,
  showResourceForm,
  ...props
}) => {
  const [columns, setColumns] = useState([]);
  const [eventCareguides, setEventCareguides] = useState([]);
  const [resourceCareguides, setResourceCareguides] = useState([]);

  const [careGuidesOwnersIds, setCareGuidesOwnersIds] = useState([]);

  const filterOptions = ['class_tags', 'account_subscription_status'];

  const appliedFilters = filterOptions.reduce((acc, key) => {
    if (key === 'class_tags') {
      const nestedObject = filter[key]
        ? filter[key].split('||').reduce((nestedAcc, item) => {
            const [nestedKey, nestedValues] = item.split(':');
            const nestedArray = nestedValues.split(',');
            nestedAcc[nestedKey] = nestedArray ?? nestedValues;
            return nestedAcc;
          }, {})
        : {};

      acc[key] = nestedObject;
    }

    if (key === 'account_subscription_status') {
      const filterData = filter[key]
        ? formatSubscriptionFilter(filter[key].split(','))
        : [];

      acc[key] = filterData;
    }

    return acc;
  }, {});

  function formatSubscriptionFilter(data) {
    // both filter options selected
    if (data.length === 5) {
      const activeState = data.at(0);
      const inactiveState = data.slice(1).toString();
      return [activeState, inactiveState];
    }

    // one option selected
    return [data.toString()];
  }

  let params = QueryString.parse(history.location.search);

  const pushToHistory = useCallback(
    (params, path) => {
      const toPath = path ?? props.location.pathname;
      history.push(QueryString.append(toPath, params));
    },
    [history, props.location.pathname]
  );

  const onSelectChange = (record, selected) => {
    updateSelectedRowKeys(record.key, selected, false);
  };

  const onSelectClear = (rows) => {
    selectInvertAll(false);
    updateSelectedRowKeys(rows, false, true);
  };

  const toggleSelectAll = (e) => {
    if (e.target.checked) {
      selectInvertAll(true);
    } else {
      if (selectedRowKeys.length > 0 && selectedRowKeys.length !== total) {
        selectInvertAll(true);
      } else {
        selectInvertAll(false);
      }
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < total
      }
      onChange={toggleSelectAll}
    />
  );

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    fixed: true,
    onSelect: onSelectChange,
    columnTitle: headerCheckbox,
  };

  const getPageSizeOptions = (total) => {
    const options = ['10', '20'];
    if (total > 20) options.push('50');
    if (total > 50) options.push('100');
    return options;
  };

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: total > 10,
    position: ['bottomCenter'],
    pageSizeOptions: getPageSizeOptions(total),
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {
        page: pagination['current'],
        limit: pagination['pageSize'],
      });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const orderUserProfilePartnership =
          sorter['field'] +
          '.' +
          (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = orderUserProfilePartnership;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    pushToHistory({ ...params, ...filter }, path);
  };

  const applyFilters = (filterParams = {}) => {
    selectInvertAll(false);
    updateSelectedRowKeys([], false, true);
    const hasFilterParams = Object.values(filterParams).length > 0;
    if (hasFilterParams) {
      const path = pathTo(component, { page: 1 });
      pushToHistory({ order, ...filterParams }, path);
    }
  };

  const clearFilters = () => {
    selectInvertAll(false);
    delete filter['class_tags'];
    delete filter['account_subscription_status'];
    pushToHistory({ order, ...filter });
    updateSelectedRowKeys([], false, true);
  };

  const onSearch = (value) => {
    selectInvertAll(false);
    params['search'] = value;
    if (params['search'] === '') {
      delete params['search'];
    }
    const path = pathTo(component);
    history.push(QueryString.append(path, params));
    updateSelectedRowKeys([], false, true);
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };

        nextColumns.forEach((column, index) => {
          storage.set(
            'local',
            `${profileId}_${column['title']}`,
            column['width']
          );
        });

        return nextColumns;
      });
    };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const parseIdsByCategories = (ids) => {
    return {
      careGuideIds: ids.map((item) => item?.id),
      ownersIds: ids.map((item) => item?.profile?.id),
    };
  };

  const redirectToNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    newWindow.focus();
  };

  const redirectToScreen = (caregiverId, screen) => {
    const urlParams = {
      id: caregiverId,
    };

    const params = {
      'page-init': page,
      'limit-init': limit,
      'order-init': order,
      ...filter,
    };

    const path = QueryString.append(pathTo(screen, urlParams), params);
    redirectToNewTab(path);
  };

  function openEventForm(careguidesIds, ownersIds = null) {
    if (ownersIds) setCareGuidesOwnersIds(ownersIds);
    setEventCareguides(careguidesIds);
    props.showEventForm();
  }

  function openResourceForm(careguidesIds) {
    setResourceCareguides(careguidesIds);
    showResourceForm();
  }

  const filterString = JSON.stringify(filter);

  useEffect(() => {
    load(page, limit, order, JSON.parse(filterString), partnershipActiveId);
  }, [partnershipActiveId, page, limit, order, filterString, load]);

  useEffect(() => {
    loadFilters(partnershipActiveId);
  }, [partnershipActiveId, loadFilters]);

  const selectInvertAll = (selectAllData) => {
    if (props.allUsersIds.length > 0 && selectAllData) {
      const { careGuideIds } = parseIdsByCategories(props.allUsersIds);
      updateSelectedRowKeys(careGuideIds, false, true);
    } else {
      updateSelectedRowKeys([], false, true);
    }
  };

  useEffect(() => {
    setColumns(
      getTableColumns(
        profileId,
        order,
        props.tableFilters,
        accessCareGuide,
        openEventForm,
        redirectToScreen,
        showResourceForm
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, props.tableFilters, pushToHistory]);

  useEffect(() => {
    loadAllIds(partnershipActiveId, JSON.parse(filterString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipActiveId, filterString]);

  useEffect(() => {
    const ownersIds = props.allUsersIds
      .filter((user) => selectedRowKeys.includes(user.id))
      .map((user) => user?.profile?.id);

    setCareGuidesOwnersIds(ownersIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowKeys]);

  return (
    <Translation>
      {(t) => (
        <Col className='members-table-wrapper'>
          <MembersListFilters
            onSearch={onSearch}
            filters={props.tableFilters}
            appliedFilters={appliedFilters}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
          />
          {!!selectedRowKeys.length && (
            <MemberListItemsSelectionBar
              openEventForm={openEventForm}
              openResourceForm={openResourceForm}
              itemsDisplayed={props.list.length}
              selectedItems={selectedRowKeys}
              totalItems={total}
              onClearSelection={() => onSelectClear([])}
            />
          )}
          <Table
            className='members-list-table'
            components={tableComponents}
            columns={resizableColumns}
            rowSelection={!isLoadingIds && rowSelection}
            dataSource={props.list}
            loading={props.isLoading}
            pagination={page === 1 && total < limit ? false : pagination}
            scroll={{ x: 1320, y: 555 }}
            onChange={handleTableChange}
          />
          <MemberEventForm
            initialParticipants={careGuidesOwnersIds}
            careGuidesIds={eventCareguides}
            start={props.eventFormStart}
            end={props.eventFormEnd}
          />

          <MemberResourceForm careGuideIds={resourceCareguides} />
        </Col>
      )}
    </Translation>
  );
};

export default withRouter(MembersListTable);

Logger.log('silly', `MembersListTable loaded.`);
